import styled from 'styled-components'


const SearchBarContainer = styled.div`
  width: auto;
  margin: 0; padding: 0;
  position: relative;
  top: 9px;
`;
const SearchBarWrapper = styled.div`
  position: relative;
  margin: 0; padding: 0;
  display: flex;
  align-items: center;
  border: 1px solid #CACACA;
  padding: 0 12px;
  border-radius: 4px;
  float: right;
`;
const Icon = styled.svg`
  margin-right: 5px;
`;
const Input = styled.input`
  margin: 0; 
  padding: 0;
  height: 34px;
  box-sizing: border-box;
  border-radius: 4px;
  border: none;
  outline: 0;
  color: #7E7E7E;

  &:focus {
    border: none;
    outline: 0;
  }
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  align-content: center;
`;
const TopWrapper = styled.div`
  display: flex;
  align-items: baseline;
  padding: 5px 0;
`;

const TopIcon = styled.img`
  padding: 0;
  margin: 0;
  margin-right: 12px;
  position: relative;
  top: 9px;

`;
const Text = styled.p`
    color: #4E4E4E;
    font-size: 20px;
    margin-right: 10px;
`;
const Export = styled.div`
  background: #F4F4F4;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  padding: 5px 13px;
  height: 30px;
  cursor: pointer;
  justify-content: space-between;
`;
const ExportText = styled.p`
  margin: 0;
  color: #484848;
`;


export { SearchBarContainer, SearchBarWrapper, Icon, Input, TopContainer, TopWrapper, TopIcon, Text, Export, ExportText}