import React from 'react';
import closeIcon from '../../assets/svg/close.svg';

class Modal extends React.Component{
    render(){
        const showHideClassname = this.props.show ? "modal display-block" : "modal display-none";
        return(
            <div className={showHideClassname}>
                <section className="modal-main">
                    {this.props.children}
                    <img src={closeIcon} alt="" className="close-icon" onClick={this.props.handleClose}/>
                </section>
            </div>
        )
    }
}

export default Modal;