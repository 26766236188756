import React, { Component } from 'react';
import { CSVLink } from "react-csv";
import moment from 'moment';
import styled from 'styled-components';
import DatePicker from "react-datepicker";
import { Redirect, withRouter } from 'react-router-dom';
import helper from '../helper';
import Header from '../shared/Header';
import Pagination from '../shared/Pagination';
import '../../App.css';
import users from '../../assets/svg/users.svg';
import down from '../../assets/svg/arrow-down.svg';
import updown from '../../assets/svg/updown.svg';
import loader from '../../assets/svg/loader.svg';

import "react-datepicker/dist/react-datepicker.css";


const SearchBarContainer = styled.div`
  width: auto;
  margin: 0; padding: 0;
  position: relative;
  top: 9px;
`;
const SearchBarWrapper = styled.div`
  position: relative;
  margin: 0; padding: 0;
  display: flex;
  align-items: center;
  border: 1px solid #CACACA;
  padding: 0 12px;
  border-radius: 4px;
  float: right;
`;
const Icon = styled.svg`
  margin-right: 5px;
`;
const Input = styled.input`
  margin: 0;
  padding: 0;
  height: 34px;
  box-sizing: border-box;
  border-radius: 4px;
  border: none;
  outline: 0;
  color: #7E7E7E;

  &:focus {
    border: none;
    outline: 0;
  }
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  align-content: center;
`;
const TopWrapper = styled.div`
  display: flex;
  align-items: baseline;
  padding: 5px 0;
`;

const TopIcon = styled.img`
  padding: 0;
  margin: 0;
  margin-right: 12px;
  position: relative;
  top: 9px;

`;
const Text = styled.p`
    color: #4E4E4E;
    font-size: 20px;
    margin-right: 10px;
`;
const Export = styled.div`
  background: #F4F4F4;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  padding: 5px 13px;
  height: 30px;
  cursor: pointer;
`;
const ExportText = styled.p`
  margin: 0;
  color: #484848;
`;



class Users extends Component {
  csvLink = React.createRef();
  state = {
    candidates: [],
    stateData: [],
    totalCandidates: 0,
    response: false,
    page: 1,
    perPage: 10,
    totalPages: 0,
    total: 0,
    skip: 0,
    filter: '',
    minAge: 1,
    maxAge: 100,
    gender: '',
    location: '',
    startDate: '',
    endDate: '',
    csvData: [],
  }
  componentDidMount() {
    this.setStateData();
    this.calculatePagination();
    this.loadUsers();
  }

  setStateData = () => {
    fetch('http://locationsng-api.herokuapp.com/api/v1/states',{
      method: 'GET'
    })
    .then(response => response.json())
    .then(responseJson => {
      let stateData = [];
      responseJson.forEach(state => {
        stateData = [...stateData, state.name];
      })
      this.setState({
        stateData,
      })
    })
  }

  loadUsers = () => {
    const { match: { params } } = this.props;
    const { page, per_page } = params;
    const parsedPage = parseInt(page);
    const parserPerPage = parseInt(per_page)
    const skip = (parsedPage - 1) * parserPerPage;
    this.setState({
      skip,
      page: parsedPage,
      perPage: parserPerPage
    }, () => {
      this.fetchUsers();
    })
  }

  calculatePagination = async () => {
    const { perPage, total } = this.state;
    const pagesObject = await helper.calculateTotalPages(total, perPage)
    const { entriesLength, totalPages } = pagesObject;
    await this.setState({
      totalCandidates: entriesLength,
      totalPages,
    })
  }

  fetchUsers = async () => {
    const { skip, perPage, minAge, maxAge, gender, location, startDate, endDate } = this.state;
    const formatStartDate = moment(startDate).format('YYYY-MM-DD');
    const formatEndDate = moment(endDate).format('YYYY-MM-DD');

    let qs = [];
    if (perPage) qs[qs.length] = `limit: ${perPage}`;
    if (skip) qs[qs.length] = `skip: ${skip}`;
    if (minAge!==1 || maxAge!==100) qs[qs.length] = `age: { min: ${minAge}, max: ${maxAge} }`;
    if (gender) qs[qs.length] = `gender: ${gender.toUpperCase()}`;
    if (location) qs[qs.length] = `location: "${location}"`;
    if (startDate && endDate) qs[qs.length] = `created_at: { start: "${formatStartDate}", end: "${formatEndDate}" }`;

    const query = `{
      candidates(${qs.join(',')}){
        collection{
          username,
          id,
          location,
          age,
          gender,
          dateCreated
        }
        meta{
          total
        }
      }
    }`
    const data = await helper.apolloQuery(query);
    if (data.error) {
      return <Redirect to='/login' />
    }
    const { candidates } = data;
    const collection = candidates && candidates.collection;
    const meta = candidates && candidates.meta;
    const total = meta && meta.total;
    await this.setState({
      total: total || 0,
      candidates: collection || [],
      response: true,
    }, ()=>{
      this.calculatePagination();
    })
  }

  exportCSV = async () => {
    const { total, location, minAge, maxAge, gender, action, startDate, endDate } = this.state;
    const formatStartDate = moment(startDate).format('YYYY-MM-DD');
    const formatEndDate = moment(endDate).format('YYYY-MM-DD');

    let qs = [`limit: ${total}`, 'skip: 0'];
    if (location) qs[qs.length] = `location: "${location}"`;
    if (minAge!==1 || maxAge!==100) qs[qs.length] = `age: { min: ${minAge}, max: ${maxAge} }`;
    if (gender) qs[qs.length] = `gender: ${gender.toUpperCase()}`;
    if (action) qs[qs.length] = `action: ${action.toUpperCase()}`;
    if (startDate && endDate) qs[qs.length] = `created_at: { start: "${formatStartDate}", end: "${formatEndDate}" }`;

    const query = `{
      candidates(${qs.join(',')}){
        collection{
          username,
          location,
          age,
          gender,
          dateCreated
        }
      }
    }`
    const data = await helper.apolloQuery(query);
    if(data.error){
      return <Redirect to='/login'/>
    }
    const { candidates } = data;
    const collection = candidates && candidates.collection;
    let csvData = [];
    collection.forEach(item => {
      const {username, location, age, gender, dateCreated} = item;
      let entry = {
        username,
        age,
        gender: gender==='RATHER_NOT_SAY'?'Rather Not Say':gender,
        location,
        date: dateCreated
      }
      csvData = [...csvData, entry]
    })
    await this.setState({
      csvData,
    },()=>{
      this.csvLink.current.link.click()
    })
  }

  next = () => {
    const { page, perPage, totalPages } = this.state;
    const nextObject = helper.handleNextPage(page, perPage, totalPages)
    const skip = nextObject && nextObject.skip;
    const newPage = nextObject && nextObject.newPage;
    if(nextObject){
      this.setState({
        response: false,
        candidates: []
      })
      this.loadPage(newPage, perPage);
      this.setState({
        skip,
        page: newPage,
      }, () => {
        this.fetchUsers();
      })
    }
  }

  prev = () => {
    const { page, perPage } = this.state;
    const previousObject = helper.handlePreviousPage(page, perPage)
    const skip = previousObject && previousObject.skip;
    const newPage = previousObject && previousObject.newPage;
    if(previousObject){
      this.setState({
        response: false,
        candidates: []
      })
      this.loadPage(newPage, perPage);
      this.setState({
        skip,
        page: newPage,
      }, () => {
        this.fetchUsers();
      })
    }
  }

  handlePage = (page, perPage) => {
    this.loadPage(page, perPage);
    const skip = (page - 1) * perPage;
    this.setState({
      skip,
      page,
      candidates: [],
      response: false,
    }, () => {
      this.fetchUsers();
    })
  }

  loadPage = (page, perPage) => {
    this.props.history.push({
      pathname: `/users/${page}/${perPage}`,
    })
  }

  viewUser = (candidate_id) => {
    this.props.history.push({
      pathname: `/test-user/${candidate_id}`,
      candidate_id,
    })
  }

  handleFilter = (value) => {
    this.setState({
      filter: value,
    })
  }

  closeFilter = () => {
    this.setState({
      filter: '',
    })
  }

  handleAge = (e, type) => {
    const age = e.target.value;
    if(type==='min' && age!==0){
      this.setState({
        minAge: age,
        candidates: [],
        response: false,
      }, () => {
        if(age){
          this.fetchUsers();
        }
      })
    }
    if(type==='max' && age!==0){
      this.setState({
        maxAge: age,
        candidates: [],
        response: false,
      }, ()=>{
        if(age){
          this.fetchUsers();
        }
      })
    }
  }

  handleGender = (gender) => {
    this.setState({
      gender,
      candidates: [],
      response: false,
    }, ()=>{
      this.fetchUsers();
    })
  }

  handleLocation = (e) => {
    this.setState({
      location: e.target.value,
      candidates: [],
      response: false,
    }, ()=>{
      this.fetchUsers();
    })
  }

  handleStartDate = (date) => {
    this.setState({
      startDate: date,
      candidates: [],
      response: false,
    }, () => {
      const {startDate, endDate} = this.state;
      if(startDate&&endDate){
        this.fetchUsers();
      }
    })
  }

  handleEndDate = (date) => {
    this.setState({
      endDate: date,
      candidates: [],
      response: false,
    }, () => {
      const {startDate, endDate} = this.state;
      if(startDate&&endDate){
        this.fetchUsers();
      }
    })
  }

  clearFilters = (type) => {
    if (type === 'all'){
      this.setState({
        gender: '',
        minAge: 1,
        maxAge: 100,
        location: '',
        startDate: '',
        endDate: '',
        candidates: [],
        response: false,
      }, () => {
        this.fetchUsers();
      })
    }
    if (type === 'gender'){
      this.setState({
        gender: '',
        candidates: [],
        response: false,
      }, () => {
        this.fetchUsers();
      })
    }
    if (type === 'age'){
      this.setState({
        minAge: 1,
        maxAge: 100,
        candidates: [],
        response: false,
      }, () => {
        this.fetchUsers();
      })
    }
    if (type === 'location'){
      this.setState({
        location: '',
        candidates: [],
        response: false,
      }, () => {
        this.fetchUsers();
      })
    }
    if (type === 'date'){
      this.setState({
        startDate: '',
        endDate: '',
        candidates: [],
        response: false,
      }, () => {
        this.fetchUsers();
      })
    }
  }

  render() {
    const { candidates, totalCandidates, gender, minAge, maxAge, response, filter, totalPages, perPage, page, stateData, location, startDate, endDate, csvData } = this.state;
    let stateOptions = stateData.map((state, index) => <option key={index}>{state}</option>);
    let headers = [
      { label: "Username", key: "username" },
      { label: "Age", key: "age" },
      { label: "Gender", key: "gender" },
      { label: "Location", key: "location" },
      { label: "Date Created", key: "date" }
    ];
    return (
      <React.Fragment>
        <Header page='users' />
        <div id='users'>
          <div className='left'>
            <p className='title'>Filter View</p>
            <div className='filter-label-top'>
              <p>All</p>
              <div className='counter'>
                <p>{totalCandidates}</p>
              </div>
            </div>
            <div className='filter-label' onClick={filter==='age'?this.closeFilter:() => this.handleFilter('age')}>
              <p>Age</p>
              <img alt='open' src={down}/>
            </div>
            {
              filter === 'age' &&
              <div className='age-filter'>
                <div>
                  min
                  <input type="number" value={minAge} onChange={(e) => this.handleAge(e, 'min')}/>
                </div>
                -
                <div>
                  max
                  <input type="number" value={maxAge} onChange={(e) => this.handleAge(e, 'max')}/>
                </div>
              </div>
            }
            <div className='filter-label' onClick={filter==='gender'?this.closeFilter:() => this.handleFilter('gender')}>
              <p>Gender</p>
              <img alt='open' src={down} />
            </div>
            {
              filter === 'gender' &&
              <div className="filter-options">
                <p onClick={()=>this.handleGender('male')}>Male</p>
                <p onClick={()=>this.handleGender('female')}>Female</p>
                <p onClick={()=>this.handleGender('RATHER_NOT_SAY')}>Rather Not Say</p>
              </div>
            }
            <div className='filter-label' onClick={filter==='location'?this.closeFilter:() => this.handleFilter('location')}>
              <p>Location</p>
              <img alt='open' src={down} />
            </div>
            {
              filter === 'location' &&
              <div className="filter-options">
                <select onChange={this.handleLocation} value={location}>
                  <option value=''>Select state</option>
                  {stateOptions}
                </select>
              </div>
            }
            <div className='filter-label' onClick={filter==='date'?this.closeFilter:() => this.handleFilter('date')}>
              <p>Date Created</p>
              <img alt='open' src={down} />
            </div>
            {
              filter === 'date' &&
              <div className="filter-options">
                <p className="date-label">Start Date</p>
                <DatePicker
                  selected={startDate}
                  onChange={this.handleStartDate}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/YYYY"
                  className="date-input"
                />
                <p className="date-label">End Date</p>
                <DatePicker
                  selected={endDate}
                  onChange={this.handleEndDate}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/YYYY"
                  className="date-input"
                />
              </div>
            }
            <div className="filters">
              {
                (minAge!==1||maxAge!==100) &&
                <div className="clear-filter" onClick={()=>{this.clearFilters('age')}}>x {minAge}-{maxAge}</div>
              }
              {
                gender &&
                <div className="clear-filter" onClick={()=>{this.clearFilters('gender')}}>x {gender==='RATHER_NOT_SAY'?'Rather Not Say':gender}</div>
              }
              {
                location &&
                <div className="clear-filter" onClick={()=>{this.clearFilters('location')}}>x {location}</div>
              }
              {
                (startDate&&endDate) &&
                <div className="clear-filter" onClick={()=>{this.clearFilters('date')}}>x {moment(startDate).format('DD/MM/YYYY')}-{moment(endDate).format('DD/MM/YYYY')}</div>
              }
              {
                (gender||minAge!==1||maxAge!==100||location||(startDate&&endDate)) &&
                <div className="clear-filter" onClick={()=>{this.clearFilters('all')}}>x clear all</div>
              }
            </div>
          </div>
          <div className='right'>
            <div className='content'>
              <TopContainer>
                <TopWrapper>
                  <TopIcon src={users} className='icon' />
                  <Text>Users</Text>
                  <Export onClick={this.exportCSV}>
                    <ExportText>Export CSV</ExportText>
                  </Export>

                  <CSVLink data={csvData}
                    headers={headers}
                    filename={`itest-candidates-${Date.now()}.csv`}
                    ref={this.csvLink}
                    className="hidden-csv">
                  </CSVLink>

                </TopWrapper>

                <SearchBarContainer>
                  <SearchBarWrapper>
                  <Icon width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.6448 10.229C11.5058 8.96905 11.8844 7.41449 11.7052 5.87443C11.526 4.33437 10.8022 2.92161 9.67773 1.91706C8.55322 0.9125 7.11037 0.38972 5.63605 0.452659C4.16172 0.515599 2.76392 1.15965 1.72056 2.25675C0.677196 3.35386 0.0646958 4.82366 0.00483942 6.37392C-0.055017 7.92418 0.442155 9.44136 1.3975 10.6238C2.35285 11.8062 3.6964 12.5673 5.16102 12.7557C6.62564 12.9441 8.10405 12.546 9.3023 11.6406L12.6569 15.1714L14 13.7591L10.6448 10.229ZM5.89391 10.8047C5.1115 10.8047 4.34667 10.5608 3.69613 10.1037C3.04559 9.64662 2.53855 8.99697 2.23914 8.23689C1.93973 7.47681 1.86139 6.64045 2.01403 5.83355C2.16666 5.02666 2.54343 4.28548 3.09667 3.70374C3.64991 3.122 4.35478 2.72584 5.12215 2.56534C5.88952 2.40483 6.68491 2.48721 7.40776 2.80204C8.1306 3.11688 8.74843 3.65003 9.18311 4.33408C9.61779 5.01813 9.8498 5.82236 9.8498 6.64506C9.84862 7.74789 9.43147 8.8052 8.68985 9.58502C7.94823 10.3648 6.94271 10.8035 5.89391 10.8047Z" fill="#148393"/>
                  </Icon>

                    <Input placeholder='Search for a name' />
                  </SearchBarWrapper>
                </SearchBarContainer>

              </TopContainer>
              <div className='table-container'>
                <table>
                  <tbody>
                    <tr>
                      <th>USERNAME <img className='updown' alt='open' src={updown} /></th>
                      <th>LOCATION <img className='updown' alt='open' src={updown} /></th>
                      <th>AGE <img className='updown' alt='open' src={updown} /></th>
                      <th>GENDER <img className='updown' alt='open' src={updown} /></th>
                      <th>DATE CREATED <img className='updown' alt='open' src={updown} /></th>
                      <th></th>
                    </tr>
                    {
                      candidates.map(candidate => {
                        const { id, username, age, gender, location, dateCreated } = candidate;
                        const createdAt = moment(dateCreated).format('DD MMMM YYYY');
                        return (
                          <tr key={id}>
                            <td>{username}</td>
                            <td>{location}</td>
                            <td>{age}</td>
                            <td>{gender==='RATHER_NOT_SAY'? 'RATHER NOT SAY': gender}</td>
                            <td>{createdAt}</td>
                            <td className='view'>
                              <span className='view' onClick={() => this.viewUser(id)}>View User</span>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
                {
                  !response &&
                  <div className="loader-div">
                    <img src={loader} alt=""/>
                  </div>
                }
                {
                  response && candidates.length===0 &&
                  <div className="loader-div">
                    <p>There are no candidates on the system yet</p>
                  </div>
                }
              </div>
            </div>
            {
              response && candidates.length > 0 &&
              <div className="bottom">
                <Pagination
                  totalPages={totalPages}
                  page={page}
                  handlePage={this.handlePage}
                  perPage={perPage}
                  prev={this.prev}
                  next={this.next}
                />
              </div>
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Users);
