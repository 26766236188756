import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import AddLocation from './AddLocation';
import locationsImage from '../../assets/svg/locations.svg';
import search from '../../assets/svg/search.svg';
import Modal from '../modal/Modal';
import Header from '../shared/Header';
import helper from '../helper';
import loader from '../../assets/svg/loader.svg';

class Locations extends Component {
  state = {
    show: false,
    locations: []
  }

  componentDidMount(){
    this.fetchCenters();
  }

  fetchCenters = async () => {
    const query = `{
      locations{
        id,
        name,
        address,
        contact
      }
    }`
    const data = await helper.apolloQuery(query);
    if(data.error){
      return <Redirect to='/login'/>
    }
    const { locations } = data;
    await this.setState({
      locations,
      response: true,
    })
  }

  showModal = (location) => {
    this.setState({ show: true, location });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  render() {
    const { show, locations, location, response } = this.state;

    return (
      <React.Fragment>
        <Header page='centers' />
        <div id='locations'>
          <div className='top'>
            <div className='location'>
              <img alt='Locations' src={locationsImage} />
              <p>Test Locations</p>
              <button className="btn" onClick={() => this.showModal()}>Add New Location</button>
            </div>
            <div className='input-div'>
              <input type="text" placeholder="Search for a location" />
              <img alt='Search' src={search} className='search' />
            </div>
          </div>
          <div className='card-div'>
            {
              locations.map(location => {
                const {id,name,address,contact} = location;
                return(
                  <div className='card' key={id}>
                    <div className='card-top'>
                      <p className='name'>{name}</p>
                      <p className='title'>ADDRESS</p>
                      <p className='address'>{address}</p>
                      <p className='title'>CONTACT</p>
                      <p className='address'>{contact}</p>
                    </div>
                    <p className='edit' onClick={() => this.showModal(location)}>Edit Details</p>
                  </div>
                )
              })
            }
            {
              !response &&
              <div className="loader-div">
                <img src={loader} alt=""/>
              </div>
            }
            {
              response && locations.length===0 &&
              <div className="loader-div">
                <p>There are no Youth Friendly Centers added yet.</p>
              </div>
            }
          </div>
          <Modal show={show} handleClose={this.hideModal}>
            {show && <AddLocation close={this.hideModal} fetchCenters={this.fetchCenters} location={location}/>}
          </Modal>
        </div>
      </React.Fragment>
    );
  }
} 

export default withRouter(Locations);
