import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import selfIcon from '../../assets/svg/self.svg';
import selfIconActive from '../../assets/svg/self-active.svg';
import partnerIcon from '../../assets/svg/partner.svg';
import partnerIconActive from '../../assets/svg/partner-active.svg';

class ViewAssessment extends Component {
  state = {
    page: 'self'
  }
  handleTab = (page) => {
    this.setState({
      page
    })
  }
  viewUser = (id) => {
    this.props.history.push({
      pathname: `/test-user/${id}`,
    })
  }
  render() {
    const { page } = this.state;
    const { entry } = this.props;
    const { candidate_id, username, age, gender, assessment } = entry;
    const { risky, self, partner } = assessment;
    return (
      <div className="view-assessment">
        <div className="top">
          <h4>View Assessment</h4>
          <div className="candidate-info">
            <div className="info">
              <h5>USERNAME</h5>
              <h4 onClick={() => {this.viewUser(candidate_id)}} className="username">{username}</h4>
            </div>
            <div className="info">
              <h5>AGE</h5>
              <h4>{age}</h4>
            </div>
            <div className="info">
              <h5>GENDER</h5>
              <h4>{gender==='RATHER_NOT_SAY'?'Rather Not Say':gender}</h4>
            </div>
            <div className="info">
              <h5>AT RISK</h5>
              <h4>{risky}</h4>
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="tab-headers">
            <div className={page === 'self' ? 'tab-header active' : 'tab-header'}
              onClick={() => this.handleTab('self')}
            >
              <span>
                <img src={page==='self'?selfIconActive:selfIcon} alt=""/>
                Self
              </span>
              {
                page === 'self' &&
                <div className="active-bar"></div>
              }
            </div>
            <div className={page === 'partner' ? 'tab-header active' : 'tab-header'}
              onClick={() => this.handleTab('partner')}
            >
              <span>
                <img src={page==='partner'?partnerIconActive:partnerIcon} alt=""/>
                Partner
              </span>
              {
                page === 'partner' &&
                <div className="active-bar"></div>
              }
            </div>
          </div>
          {
            page === 'self' &&
            <div className={page==='self'?"questions active":"questions"}>
              {
                self.map((questions, index) => {
                  const { question, response } = questions;
                  let questionSentence;
                  if (question === 'penis-­anus') {
                    questionSentence = 'Have you had penis-­anus sex unprotected or while under the influence and do not remember if protection was used?';
                  }
                  if (question === 'penis-vagina') {
                    questionSentence = 'Have you had penis-vagina sex unprotected or while under the influence and do not remember if protection was used?';
                  }
                  if (question === 'needles') {
                    questionSentence = 'Have you shared needles or injection equipment?';
                  }
                  if (question === 'sti') {
                    questionSentence = 'Have you tested positive for any sexually transmitted infection (STI)? Common STIs include chlamydia, gonorrhea, genital herpes, HPV/genital warts, and syphilis.';
                  }
                  return (
                    <div className="question" key={index}>
                      <p>{questionSentence}</p>
                      <div className="answer">{response}</div>
                    </div>
                  )
                })
              }
            </div>
          }
          {
            page === 'partner' &&
            <div className={page==='partner'?"questions active":"questions"}>
              {
                partner.map((questions, index) => {
                  const { question, response } = questions;
                  let questionSentence;
                  if (question === 'penis-­anus') {
                    questionSentence = 'Have you had penis-­anus sex unprotected or while under the influence and do not remember if protection was used?';
                  }
                  if (question === 'penis-vagina') {
                    questionSentence = 'Have you had penis-vagina sex unprotected or while under the influence and do not remember if protection was used?';
                  }
                  if (question === 'needles') {
                    questionSentence = 'Have you shared needles or injection equipment?';
                  }
                  if (question === 'sti') {
                    questionSentence = 'Have you tested positive for any sexually transmitted infection (STI)? Common STIs include chlamydia, gonorrhea, genital herpes, HPV/genital warts, and syphilis.';
                  }
                  return (
                    <div className="question" key={index}>
                      <p>{questionSentence}</p>
                      <div className="answer">{response}</div>
                    </div>
                  )
                })
              }
            </div>
          }
        </div>
      </div>
    )
  }
}

export default withRouter(ViewAssessment);
