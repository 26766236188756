import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { ProtectedRoute } from './components/auth/Authentication';
import Overview from './components/overview/Overview';
import Assessments from './components/assessment/Assessment';
import TestSubmissions from './components/testSubmissions/TestSubmissions';
import ViewCandidate from './components/candidate/ViewCandidate';
import Signin from './components/auth/SignIn';
import Users from './components/users/Users';
import Centers from './components/locations/Locations';
import Profile from './components/profile/Profile';
import ResetPassword from './components/auth/ResetPassword';
import NewPassword from './components/auth/NewPassword';

function App() {
  return (
    <div>
    <Router>
      <Switch>
        <Route exact path="/" render={() => (
          <Redirect to="/overview"/>
        )}/>
        <Route path='/login' component={Signin} />
        <ProtectedRoute exact path='/overview' component={Overview} />
        <ProtectedRoute path='/overview/submission/:candidate_id/:test_id' component={Overview} />
        <ProtectedRoute exact path='/submissions/:page/:per_page' component={TestSubmissions} />
        <ProtectedRoute path='/submissions/view/:candidate_id/:test_id' component={TestSubmissions} />
        <ProtectedRoute path='/test-user/:candidate_id' component={ViewCandidate} />
        <ProtectedRoute path='/users/:page/:per_page' component={Users} />
        <ProtectedRoute path='/assessments/:page/:per_page' component={Assessments} />
        <ProtectedRoute path='/centers' component={Centers}/>
        <ProtectedRoute path='/admin-profile' component={Profile} />
        <Route path='/password/reset' component={ResetPassword} />
        <Route path='/password/new' component={NewPassword} />
      </Switch>
    </Router>
  </div>
  );
}

export default App;
