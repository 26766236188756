import React, { Component } from 'react'
import {withRouter} from 'react-router-dom';
import Header from '../shared/Header';
import avatar from '../../assets/svg/admin_profile.svg';

class Profile extends Component {
  logout = () => {
    localStorage.clear();
    this.props.history.push({
      pathname: `/login`,
    })
  }
  resetPasswordScreen = () => {
    this.props.history.push({
      pathname: `/password/reset`,
    })
  }
  render() {
    return (
      <React.Fragment>
        <Header />
        <div id='profile'>
          <div className='main-box'>
            <img alt='Avatar' src={avatar} />
            <p className='profile-name'>Admin</p>
            <div className='btn-box'>
              <button className="btn1" onClick={this.logout}>Logout</button>
              <button className="btn2" onClick={this.resetPasswordScreen}>Change Password</button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
} 

export default withRouter(Profile);
