import React, { Component } from 'react'
import moment from 'moment';
import helper from '../helper';
import Header from '../shared/Header';
import ViewAssessment from '../assessment/ViewAssessment';
import Modal from '../modal/Modal';
import assessments_icon from '../../assets/svg/assesment.svg';
import request from '../../assets/svg/new_call_request.svg';
import loader from '../../assets/svg/loader.svg';

export default class ViewSubmissions extends Component {
  state = {
    candidateId: '',
    submissions: [],
    assessments: [],
    assessmentNo: 0,
    submissionNo: 0,
    username: '',
    gender: '',
    location: '',
    age: '',
    response: false,
  }
  componentDidMount() {
    const { match: { params } } = this.props;
    const { candidate_id } = params;
    this.fetchSubmissions(candidate_id);
    this.fetchAssessments(candidate_id);
    this.getUser(candidate_id);
  }

  fetchSubmissions = async (candidate_id) => {
    const query = `{
      submissions(candidate_id: "${candidate_id}"){
        collection{
          username,
          candidate_id,
          location,
          age,
          test{
            id,
            created_at,
            result,
            state,
            callRequest{
              type
            }
          }
        }
        meta{
          total
        }
      }
    }`
    const data = await helper.apolloQuery(query);
    const { submissions } = data;
    const { collection } = submissions;
    const meta = submissions && submissions.meta;
    const total = meta && meta.total;
    await this.setState({
      submissions: collection,
      submissionNo: total || 0,
      response: true,
    })
  }

  fetchAssessments = async (candidate_id) => {
    const query = `{
      assessments(candidate_id: "${candidate_id}"){
        collection{
          username,
          candidate_id,
          location,
          age,
          gender,
          assessment{
            id,
            created_at,
            self{
              question,
              response
            },
            partner{
              question,
              response
            },
            risky
          }
        }
        meta{
          total
        }
      }
    }`
    const data = await helper.apolloQuery(query);
    const { assessments } = data;
    const collection = assessments && assessments.collection;
    const meta = assessments && assessments.meta;
    const total = meta && meta.total;
    await this.setState({
      assessments: collection,
      assessmentNo: total || 0,
    })
  }

  getUser = async (candidate_id) => {
    const query = `{
      candidate(id: "${candidate_id}"){
        username,
        gender,
        location,
        age,
      }
    }`
    const data = await helper.apolloQuery(query);
    const { candidate } = data;
    const { username, gender, location, age } = candidate;
    await this.setState({
      username,
      gender,
      location,
      age
    })
  }

  openModal = (assessment) => {
    this.setState({
      show: true,
      details: assessment,
    })
  }

  closeModal = () => {
    this.setState({
      show: false
    })
  }

  render() {
    const { username, gender, location, age, submissions, assessments, response, assessmentNo, details, show, submissionNo } = this.state;
    return (
      <React.Fragment>
        <Header page='users'/>
        <div id='viewSubmissions'>
          {
            response &&
            <React.Fragment>
              <div className='left'>
                <div className='left-top-card'>
                  <div className='avatar-div'>
                    <div className='avatar'>
                      <p className='avatar-text'>KW</p>
                    </div>
                  </div>
                  <p className='name'>{username}</p>
                  {
                    age && gender && location &&
                    <div className='row'>
                      <div className='row-item'>
                        <p className='title'>AGE</p>
                        <p className='detail'>{age}</p>
                      </div>
                      <div className='row-item'>
                        <p className='title'>SEX</p>
                        <p className='detail'>{gender}</p>
                      </div>
                      <div className='row-item'>
                        <p className='title'>LOCATION</p>
                        <p className='detail'>{location}</p>
                      </div>
                    </div>
                  }
                  {
                    !age && !gender && !location &&
                    <div className='row'>
                      <p className="no-details">Candidate hasn't filled in details yet</p>
                    </div>
                  }
                </div>
                <div className='left-bottom-card'>
                  <div className='bottom-card-up'>
                    <p className='title'>Assesment Records</p>
                    <p className='detail'>{assessmentNo}</p>
                  </div>
                  {
                    assessments.map(entry => {
                      const { assessment } = entry;
                      const { id, created_at } = assessment;
                      const date = moment(created_at).format('DD MMMM YYYY');
                      return (
                        <div className='bottom-card-down' key={id}>
                          <p className='date'>{date}</p>
                          <p className='view' onClick={() => this.openModal(entry)}>View</p>
                        </div>
                      )
                    })
                  }
                </div>

              </div>
              <div className='right'>
                <div className='top'>
                  <img alt='Assessments' src={assessments_icon} className='icon' />
                  <p className='text'>Test Submission <b>({submissionNo})</b></p>
                  {/* <div className='export'>
                    <p className='export-text'>Export</p>
                    <img className='open' alt='open' src={open} />
                  </div> */}
                </div>
                {
                  submissions.length === 0 && <p>Candidate hasn't taken any test yet.</p>
                }
                {
                  submissions.reverse().map(submission => {
                    const { test } = submission;
                    const { id, created_at, callRequest, state, result } = test;
                    const dateTaken = moment(created_at).format('DD MMMM YYYY • hh:mmA');
                    const action = callRequest && callRequest.type;
                    const token = localStorage.getItem('access_token');
                    return (
                      <div className='result' key={id}>
                        <img className='result-img' alt='Result' src={`${result}?access_token=${token}`} />
                        <div className='result-detail'>
                          <div className='top-row'>
                            <p className='result-text'>HIV {state} Result</p>
                            <div className='request'>
                              {!action && <p className='call'>No Action</p>}
                              {action && 
                                <React.Fragment>
                                  <p className='call'>{action}</p>
                                  <img className='request-icon' alt='icon' src={request} />
                                </React.Fragment>
                              }
                            </div>
                          </div>
                          <div className='bottom-row'>
                            <p className='time'>{dateTaken}</p>
                            {/* <div className='export'>
                              <p className='export-text'>Export</p>
                              <img className='open' alt='open' src={open} />
                            </div> */}
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </React.Fragment>
          }
          {
            !response &&
            <div className="loader-div">
              <img src={loader} alt="" />
            </div>
          }
        </div>
        <Modal show={show} handleClose={this.closeModal}>
          {show && <ViewAssessment entry={details}/>}
        </Modal>
      </React.Fragment>
    );
  }
}
