import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import loader from '../../assets/svg/loader.svg';
import { baseUrl } from '../url';
import helper from '../helper';
import Modal from '../modal/Modal';

class NewPassword extends Component {
  state = {
    isAuthenticated: false,
    response: false,
    request: false,
    show: false,
  }
  componentDidMount = () => {
    this.authenticateUser();
  }
  authenticateUser = () => {
    const values = queryString.parse(this.props.location.search);
    const { email, token } = values;
    fetch(`${baseUrl}/admin/auth/reset/verify/token`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      credentials: 'same-origin',
      body: JSON.stringify({ email, token })
    }).then(response => {
      this.setState({
        response: true,
      })
      if (helper.range(200, 299).indexOf(response.status) !== -1) {
        return response.json();
      }
      throw response
    }).then(responseJson => {
      this.setState({
        successMessage: responseJson.message,
        error: false,
      })
    }).catch(error => {
      error.json().then(e => {
        this.setState({
          emailError: e.message,
          error: true,
        })
      })
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'Error']: '',
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { password, new_password, passwordError, new_passwordError } = this.state;
    if (!password) {
      this.setState({
        passwordError: 'Please enter your new password',
      })
    }
    if (!new_password) {
      this.setState({
        new_passwordError: 'Please enter a matching password',
      })
    }
    if (password !== new_password) {
      this.setState({
        new_passwordError: 'Password does not match',
      })
    }
    if (password && new_password && !passwordError && !new_passwordError) {
      const values = queryString.parse(this.props.location.search);
      const { email, token } = values;
      this.setState({
        request: true
      })
      fetch(`${baseUrl}/admin/auth/reset/password`, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        credentials: 'same-origin',
        body: JSON.stringify({ 
          email,
          token,
          password,
          password_confirmation: new_password
        })
      }).then(response => {
        this.setState({
          response: true,
          request: false,
        })
        if (helper.range(200, 299).indexOf(response.status) !== -1) {
          return response.json();
        }
        throw response
      }).then(responseJson => {
        this.setState({
          successMessage: responseJson.message,
          show: true
        })
      }).catch(error => {
        error.json().then(e => {
          console.log(e);
          this.setState({
            emailError: e.message,
          })
        })
      })
    }
  }

  hideModal = () => {
    this.setState({ show: false }, ()=>{
      this.logout();
    });
  };

  logout = () => {
    localStorage.clear();
    this.props.history.push({
      pathname: '/login'
    })
  }

  render() {
    const { response, error, passwordError, new_passwordError, request, show, successMessage } = this.state;
    return (
      <div id='new-password'>
        {
          !response &&
          <div className="loader-div">
            <img src={loader} className="big-loader" alt="" />
          </div>
        }
        {
          response && !error &&
          <div className='auth-box'>
            <p className='headerText'>Please enter the details below to <br />reset your password</p>
            <div className='form-box'>
              <label htmlFor="password">New Password
              <input type="password" className='email' name="password" onChange={this.handleChange} />
                <p className="error-text">{passwordError}</p>
              </label>
              <label htmlFor="new_password">Re-enter New Password
              <input type="password" name="new_password" onChange={this.handleChange} />
                <p className="error-text">{new_passwordError}</p>
              </label>
              <button type="submit" className="btn" onClick={this.handleSubmit}>Save Changes</button>
              {
                request &&
                <div className="loader-div">
                  <img src={loader} alt="" />
                </div>
              }
            </div>
          </div>
        }
        {
          response && error &&
          <div className="error-message">
            <p>The link provided has expired or is invalid, Please check the link and try again.</p>
          </div>
        }
        <Modal show={show} handleClose={this.hideModal}>
          <div className="reset-modal">
            <p>{successMessage}</p>
            <button onClick={this.hideModal}>Ok</button>
          </div>
        </Modal>
      </div>
    );
  }
} 

export default withRouter(NewPassword);