import React, { Component } from 'react';
import moment from 'moment';
import helper from '../helper';
import { Redirect, Link } from 'react-router-dom';
import Header from '../shared/Header';
import allSubmissionsIcon from '../../assets/svg/submissions.svg';
import submissionsIcon from '../../assets/svg/metrics_submissions.svg';
import assessmentsIcon from '../../assets/svg/metrics_assessments.svg';
import usersIcon from '../../assets/svg/metrics_users.svg';
import viewAll from '../../assets/svg/view-all.svg';
import updown from '../../assets/svg/updown.svg';
import request from '../../assets/svg/new_call_request.svg';
import callRequestsIcon from '../../assets/svg/call_requests.svg';
import arrowDown from '../../assets/svg/arrow-down.svg';
import ViewSubmission from '../testSubmissions/ViewSubmission';
import Modal from '../modal/Modal';
import loader from '../../assets/svg/loader.svg';
import { baseUrl } from '../url';

export default class Overview extends Component {
  state = {
    submissions: [],
    response: false,
    totalSubmissions: 0,
    totalAssessments: 0,
    totalUsers: 0,
    requestTab: 'pending',
    show: false,
    candidatesToday: 0,
    assessmentsToday: 0,
    submissionsToday: 0,
    callRequests: [],
    pendingRequests: 0,
    completedRequests: 0,
    dateSort: 'desc',
    showAlert: false,
    completeResponse: true,
  }
  componentDidMount() {
    this.fetchSubmissions();
    this.fetchTotalAssessments();
    this.fetchTotalUsers();
    this.fetchTodayMetrics();
    this.fetchCallRequests();
    const { match: { params } } = this.props;
    const { candidate_id, test_id } = params;
    if (candidate_id && test_id) {
      this.setState({
        show: true,
      })
    }
  }
  fetchSubmissions = async () => {
    const query = `{
      submissions(limit: 10){
        collection{
          username,
          candidate_id,
          location,
          age,
          gender,
          test{
            id,
            created_at,
            result,
            state,
            callRequest{
              type,
            }
          }
        }
        meta{
          total
        }
      }
    }`
    const data = await helper.apolloQuery(query);
    if (data.error) {
      return <Redirect to='/login' />
    }
    const { submissions } = data;
    const { collection, meta } = submissions;
    const total = meta && meta.total;
    await this.setState({
      submissions: collection,
      totalSubmissions: total,
      response: true,
    })
  }

  fetchCallRequests = async () => {
    const { requestTab, dateSort } = this.state;
    this.setState({
      callResponse: false,
    })
    let qs = [];
    if (requestTab) qs[qs.length] = `status: ${requestTab.toUpperCase()}`;
    if (dateSort) qs[qs.length] = `dateChronology: ${dateSort.toUpperCase()}`;

    const query = `{
      calls(${qs.join(',')}){
        collection{
          username,
          candidate_id,
          location,
          age,
          gender,
          test{
            id,
            created_at,
            result,
            state,
            callRequest{
              type,
              phoneNo,
              resolved
            }
          }
        }
        meta{
          totalCompleted
          totalPending
        }
      }
    }`
    const data = await helper.apolloQuery(query);
    if (data.error) {
      return <Redirect to='/login' />
    }
    const { calls } = data;
    const { collection, meta } = calls;
    const { totalCompleted, totalPending } = meta;
    await this.setState({
      callRequests: collection,
      pendingRequests: totalPending,
      completedRequests: totalCompleted,
      callResponse: true,
    })
  }

  fetchTotalAssessments = async () => {
    const query = `{
      assessments{
        meta{
          total
        }
      }
    }`
    const data = await helper.apolloQuery(query);
    if (data.error) {
      return <Redirect to='/login' />
    }
    const { meta } = data.assessments;
    const total = meta && meta.total;
    await this.setState({
      totalAssessments: total
    })
  }

  fetchTotalUsers = async () => {
    const query = `{
      candidates{
        meta{
          total
        }
      }
    }`
    const data = await helper.apolloQuery(query);
    if (data.error) {
      return <Redirect to='/login' />
    }
    const { meta } = data.candidates;
    const total = meta && meta.total;
    await this.setState({
      totalUsers: total
    })
  }

  fetchTodayMetrics = async () => {
    const date = moment().format('YYYY-MM-DD');
    const candidatesQuery = `{
      candidates(created_at: { start: "${date}", end: "${date}" }){
        meta{
          total
        }
      }
    }`;
    const assessmentsQuery = `{
      assessments(created_at: { start: "${date}", end: "${date}" }){
        meta{
          total
        }
      }
    }`;
    const submissionsQuery = `{
      submissions(created_at: { start: "${date}", end: "${date}" }){
        meta{
          total
        }
      }
    }`;
    const candidatesData = await helper.apolloQuery(candidatesQuery);
    const assessmentsData = await helper.apolloQuery(assessmentsQuery);
    const submissionsData = await helper.apolloQuery(submissionsQuery);
    const candidatesToday = candidatesData.candidates.meta && candidatesData.candidates.meta.total;
    const assessmentsToday = assessmentsData.assessments.meta && assessmentsData.assessments.meta.total;
    const submissionsToday = submissionsData.submissions.meta && submissionsData.submissions.meta.total;
    await this.setState({
      candidatesToday,
      assessmentsToday,
      submissionsToday
    })
  }

  completeCall = (id) => {
    this.setState({
      completeResponse: false
    })
    const token = `Bearer ${localStorage.getItem('access_token')}`;
    let url = `${baseUrl}/admin/calls/${id}`;
    fetch(url, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': token,
      },
      credentials: 'same-origin',
      body: JSON.stringify({
        status: "completed"
      })
    }).then(response => {
      this.setState({
        completeResponse: true,
      })
      if (helper.range(200, 299).indexOf(response.status) !== -1) {
        return response.json();
      } else {
        console.log(Promise.resolve(response.json()));
      }
    }).then(responseJson => {
      if (responseJson) {
        this.setState({
          showAlert: true,
        }, () => {
          this.closeAlert();
        })
      }
    })
  }

  closeAlert = () => {
    setTimeout(() => {
      this.setState({
        showAlert: false
      },() => {
        this.fetchCallRequests();
        this.closeOptions();
      })
    }, 1000);
  }

  openModal = (candidate_id, test_id, profile) => {
    this.props.history.push({
      pathname: `/overview/submission/${candidate_id}/${test_id}`,
      candidate_id,
      test_id,
    })
    this.setState({
      show: true,
      profile,
    })
  }

  closeModal = () => {
    this.props.history.push({
      pathname: `/overview`
    })
    this.setState({
      show: false
    })
  }

  handleTab = (requestTab) => {
    this.setState({
      requestTab,
      callRequests: [],
    }, () => {
      this.fetchCallRequests();
    })
  }

  handleDateSort = (value) => {
    this.setState({
      dateSort: value,
      callRequests: [],
    }, () => {
      this.fetchCallRequests();
    })
  }
  openOptions = (id) => {
    this.setState({
      openId: id
    })
  }

  closeOptions = () => {
    this.setState({
      openId: ''
    })
  }

  render() {
    const {
      show,
      profile,
      submissions,
      totalSubmissions,
      totalAssessments,
      totalUsers,
      requestTab,
      candidatesToday,
      assessmentsToday,
      submissionsToday,
      callRequests,
      pendingRequests,
      completedRequests,
      openId,
      callResponse,
      dateSort,
      completeResponse,
      showAlert,
    } = this.state;
    return (
      <React.Fragment>
        <Header page='overview' />
        <div id='overview'>
          <div className="left-side">
            <div className='welcome'>
              <p className='welcome-text'>Welcome Admin</p>
              <div className='card-row'>
                <div className="card-container">
                  <Link to="/submissions/1/10" style={{ textDecoration: 'none' }}>
                    <div className='top-card'>
                      <div>
                        <p className='title'>Total Submissions</p>
                        <div className='detail-row'>
                          <p className='count'>{totalSubmissions}</p>
                          <div className={submissionsToday ? 'badge' : 'badge zero'}>
                            <p className='badge-text'>{submissionsToday || 0} Today</p>
                          </div>
                        </div>
                      </div>
                      <img alt='Submissions' src={submissionsIcon} />
                    </div>
                  </Link>
                </div>
                <div className="card-container">
                  <Link to="/assessments/1/10" style={{ textDecoration: 'none' }}>
                    <div className='top-card'>
                      <div>
                        <p className='title'>Assesment Records</p>
                        <div className='detail-row'>
                          <p className='count'>{totalAssessments}</p>
                          <div className={assessmentsToday ? 'badge' : 'badge zero'}>
                            <p className='badge-text'>{assessmentsToday || 0} Today</p>
                          </div>
                        </div>
                      </div>
                      <img alt='Submissions' src={assessmentsIcon} />
                    </div>
                  </Link>
                </div>
                <div className="card-container">
                  <Link to="/users/1/10" style={{ textDecoration: 'none' }}>
                    <div className='top-card'>
                      <div>
                        <p className='title'>Total Users</p>
                        <div className='detail-row'>
                          <p className='count'>{totalUsers}</p>
                          <div className={candidatesToday ? 'badge' : 'badge zero'}>
                            <p className='badge-text'>{candidatesToday || 0} Today</p>
                          </div>
                        </div>
                      </div>
                      <img alt='Submissions' src={usersIcon} />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className='main-card'>
              <div className='content'>
                <div className='top'>
                  <div className='recent'>
                    <img alt='Assessments' src={allSubmissionsIcon} className='icon' />
                    <p className='text'>Recent Submissions</p>
                  </div>
                  <Link to="/submissions/1/10" style={{ textDecoration: 'none' }}>
                    <div className='export'>
                      <p className='export-text'>View All</p>
                      <img className='open' alt='open' src={viewAll} />
                    </div>
                  </Link>
                </div>
                <div className='table-container'>
                  <table>
                    <tbody>
                      <tr>
                        <th>USERNAME <img className='updown' alt='open' src={updown} /></th>
                        <th>LOCATION <img className='updown' alt='open' src={updown} /></th>
                        <th>AGE <img className='updown' alt='open' src={updown} /></th>
                        <th>DATE TAKEN <img className='updown' alt='open' src={updown} /></th>
                        <th>ACTION <img className='updown' alt='open' src={updown} /></th>
                        <th></th>
                      </tr>
                      {
                        submissions.map(submission => {
                          const { candidate_id, username, location, age, test } = submission;
                          const { id, created_at, callRequest } = test;
                          const dateTaken = moment(created_at).format('DD MMMM YYYY');
                          const action = callRequest && callRequest.type;
                          return (
                            <tr key={id}>
                              <td>{username}</td>
                              <td>{location}</td>
                              <td>{age}</td>
                              <td>{dateTaken}</td>
                              <td>
                                {!action && 'No Action'}
                                {action &&
                                  <React.Fragment>
                                    {action}
                                    <img className='request-icon' alt='icon' src={request} />
                                  </React.Fragment>
                                }
                              </td>
                              <td>
                                <span className='view' onClick={() => this.openModal(candidate_id, id)}>View Submission</span>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="right-side">
            <div className="call-requests">
              <div className="top">
                <img src={callRequestsIcon} alt="" />
                <h5>Call Requests</h5>
              </div>
              <div className="tab-headers">
                <div className={requestTab === 'completed' ? 'tab-header active' : 'tab-header'}
                  onClick={() => this.handleTab('completed')}
                >
                  <span>
                    Completed<span className="no">{completedRequests}</span>
                  </span>
                  {
                    requestTab === 'completed' &&
                    <div className="active-bar"></div>
                  }
                </div>
                <div className={requestTab === 'pending' ? 'tab-header active' : 'tab-header'}
                  onClick={() => this.handleTab('pending')}
                >
                  <span>
                    Pending<span className="no">{pendingRequests}</span>
                  </span>
                  {
                    requestTab === 'pending' &&
                    <div className="active-bar"></div>
                  }
                </div>
              </div>
              <div className="sort">
                <p>Sort by Date:</p>
                <div className={dateSort === 'desc' ? "sort-btn selected" : "sort-btn"} onClick={() => this.handleDateSort('desc')}>Recent to Old</div>
                <div className={dateSort === 'asc' ? "sort-btn selected" : "sort-btn"} onClick={() => this.handleDateSort('asc')}>Old to Recent</div>
              </div>
              <React.Fragment>
                {
                  callRequests.map(request => {
                    const { candidate_id, username, location, age, gender, test } = request;
                    const { id, created_at, callRequest } = test;
                    const { phoneNo, resolved } = callRequest;
                    const dateTaken = moment(created_at).format('DD MMMM YYYY • hh:mmA');
                    // const action = callRequest && callRequest.type;
                    return (
                      <div className="call-card" key={id}>
                        <div className="main">
                          <div>
                            <h5>{username.slice(0, -4)} <span>{phoneNo}</span></h5>
                            <p>{gender} • {age} • {location}</p>
                          </div>
                          <div className="relative-div">
                            <p className="change">CHANGE STATUS</p>
                            {
                              !resolved &&
                              <div className="action-btn" onClick={id === openId ? this.closeOptions : () => this.openOptions(id)}>Pending <img src={arrowDown} alt="" /></div>
                            }
                            {
                              resolved &&
                              <div className="action-btn completed">Call Completed</div>
                            }
                            {
                              openId === id &&
                              <div className="options">
                                <p className="completed" onClick={() => this.completeCall(id)}>Complete call</p>
                                <p onClick={this.closeOptions}>Pending</p>
                              </div>
                            }
                          </div>
                        </div>
                        <div className="bottom">
                          <p className="date-time">{dateTaken}</p>
                          <p className="view" onClick={() => this.openModal(candidate_id, id, false)}>
                            View Submission
                            </p>
                        </div>
                      </div>
                    )
                  })
                }
              </React.Fragment>
              {
                callRequests.length === 0 && callResponse &&
                <div className="empty-state">
                  <p>No {requestTab} call requests yet.</p>
                </div>
              }
              {
                !callResponse &&
                <div className="loader-div">
                  <img src={loader} alt="" />
                </div>
              }
              {
                !completeResponse &&
                <div className="loader-modal">
                  <img src={loader} alt="" />
                </div>
              }
              {
                showAlert &&
                <div className="loader-modal">
                  <div className="call-complete">
                    <p>Call completed!</p>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        <Modal show={show} handleClose={this.closeModal}>
          {show && <ViewSubmission profile={profile} />}
        </Modal>
      </React.Fragment>
    );
  }
}
