import React, { Component } from 'react'
import authLogo from '../../assets/svg/auth-logo.svg';
import authCheckmark from '../../assets/svg/auth-checkmark.svg';
import authError from '../../assets/svg/auth-error.svg';
import loader from '../../assets/svg/loader.svg';
import {baseUrl} from '../url';
import helper from '../helper';

export default class ResetPassword extends Component {
  state = {
    successMessage: '',
    email: '',
    request: false,
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'Error']: '',
    })
  }
  resetRequest = () => {
    const { email, emailError } = this.state;
    if (!email) {
      this.setState({
        emailError: 'Please enter a valid email address'
      })
    }
    if (email && !emailError) {
      this.setState({
        request: true
      })
      fetch(`${baseUrl}/admin/auth/forgot/password`,{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        credentials: 'same-origin',
        body: JSON.stringify({email})
      }).then(response => {
        this.setState({
          request: false,
        })
        if (helper.range(200, 299).indexOf(response.status) !== -1) {
          return response.json();
        }
        throw response
      }).then(responseJson => {
          this.setState({
            successMessage: responseJson.message
          })
      }).catch(error => {
        error.json().then(e => {
          console.log(e);
          this.setState({
            emailError: e.message
          })
        })
      })
    }
  }
  render() {
    const { successMessage, emailError, request } = this.state;
    return (
      <div id='reset-password'>
        <div className='auth-box'>
          <img src={authLogo} className="auth-logo" alt="" />
          <p className='headerText'>Enter your email to reset your password</p>
          <div className='form-box'>
            <label htmlFor="email">Email Address</label>
            <input type="text" className='email' name="email" onChange={this.handleChange} />
            <button type="submit" className="btn" onClick={this.resetRequest}>Reset Password</button>
          </div>
          {
            emailError &&
            <div className='sent-div error'>
              <img src={authError} alt="" />
              <p className='sent-text'>{emailError}</p>
            </div>
          }
          {
            successMessage &&
            <div className='sent-div'>
              <img src={authCheckmark} alt="" />
              <p className='sent-text'>{successMessage}</p>
            </div>
          }
          {
            request && 
            <div className="loader-div">
              <img src={loader} alt=""/>
            </div>
          }
        </div>
      </div>
    );
  }
} 
