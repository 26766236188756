import React, { Component } from 'react';
import loader from '../../assets/svg/loader.svg';
import { baseUrl } from '../url';
import helper from '../helper';

export default class AddLocation extends Component {
  state = {
    centername: '',
    address: '',
    contactno: '',
    request: false,
    success: false,
    edit: false,
  }
  componentDidMount(){
    const {location} = this.props;
    if(location){
      const {id, name, address, contact} = location;
      this.setState({
        locationId: id,
        centername: name,
        address,
        contactno: contact,
        edit: true,
      })
    }
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name+'Error']: '',
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {centername, address, contactno, locationId, edit} = this.state;
    const centernameError = !centername?'Please enter a center name':'';
    const addressError = !address?'Please enter a valid address':'';
    const contactnoError = !contactno?'Please enter a valid phone number':'';
    this.setState({
      centernameError,
      addressError,
      contactnoError,
    })

    let url;
    let verb;
    let entry;
    if(!edit){
      url=`${baseUrl}/admin/tests/location`;
      verb = 'POST';
      entry = [{
        name: centername,
        contact: contactno,
        address,
      }];
    }else{
      url =`${baseUrl}/admin/tests/location/${locationId}`;
      verb = 'PUT';
      entry = {
        name: centername,
        contact: contactno,
        address,
      };
    }

    if(centername && address && contactno){
      const token = `Bearer ${localStorage.getItem('access_token')}`;
      this.setState({
        request: true
      })
      fetch(url, {
        method: verb,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': token,
        },
        credentials: 'same-origin',
        body: JSON.stringify(entry)
      }).then(response => {
        this.setState({
          request: false,
        })
        if (helper.range(200, 299).indexOf(response.status) !== -1) {
          return response.json();
        }
        throw response;
      }).then(responseJson => {
        if (responseJson) {
          this.setState({
            success: true,
          })
        }
      }).catch(error => {
        if(error.status===400){
          error.json().then(e => {
            this.setState(prevState => {
              return{
                backendError: e.message,
              }
            })
          });
        }
        if(error.status===422){
          error.json().then(e => {
            this.setState(prevState => {
              return{
                backendError: e.message,
              }
            })
          });
        }
      })
    }
  }

  close = () => {
    this.props.close();
    this.props.fetchCenters();
  }

  render() {
    const {centername, address, contactno, centernameError, addressError, contactnoError, request, success, edit, backendError} = this.state;
    return (
      <React.Fragment>
        {
          !success && 
          <form className='location-modal'>
          {
            edit?
            <p className='header'>Edit Test Location</p>
            :
            <p className='header'>Add Test Location</p>
          }
  
            <label htmlFor="centrename">Centre Name
            <input type="text" id="centername" name="centername" value={centername} onChange={this.handleChange}/>
            <p className="error-text">{centernameError}</p>
            </label>
            <label htmlFor="address">Address
            <textarea id="address" name="address" value={address} onChange={this.handleChange}/>
            <p className="error-text">{addressError}</p>
            </label>
            <label htmlFor="contactno">Contact Number
            <input type="tel" id="contactno" name="contactno" value={contactno} onChange={this.handleChange}/>
            <p className="error-text">{contactnoError}</p>
            </label>
            {/* <div className='add-div'>
                  <p className='add'>Add Another Contact</p>
                </div> */}
            <button className="btn" onClick={this.handleSubmit}>Save</button>
            <p className="backend-error-text">{backendError}</p>
            {
              request && 
              <div className="loader-div">
                <img src={loader} alt=""/>
              </div>
            }
          </form>
        }
        {
          success &&
          <div className="success-div">
            <p>Center {edit?'edited':'added'} successfully!</p>
            <button onClick={this.close}>ok</button>
          </div>
        }
      </React.Fragment>
    )
  }
}
