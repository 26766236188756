import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import moment from 'moment';
import helper from '../helper';
import request from '../../assets/svg/new_call_request.svg';
import loader from '../../assets/svg/loader.svg';

class ViewSubmission extends Component {
  state = {
    token: '',
    response: false,
  }

  componentDidMount(){
    this.fetchSubmission();
    const token = localStorage.getItem('access_token');
    this.setState({
      token,
    })
  }

  fetchSubmission = async () => {
    const { match: { params } } = this.props;
    const { test_id } = params;
    const query = `{
      submission(id:"${test_id}"){
        username,
        candidate_id,
        location,
        age,
        gender,
        test{
          id,
          created_at,
          result,
          state,
          callRequest{
            type
          }
        }
      }
    }`
    const data = await helper.apolloQuery(query);
    if(data.error){
      return <Redirect to='/login'/>
    }
    const { submission } = data;
    const {username, candidate_id, age, gender, test} = submission;
    const { created_at, callRequest, result, state } = test;
    await this.setState({
      username,
      candidate_id,
      age,
      gender,
      test,
      created_at,
      callRequest,
      result,
      state,
      response: true,
    })
  }


  viewProfile = (candidate_id) => {
    this.props.history.push({
      pathname: `/test-user/${candidate_id}`,
      candidate_id,
    })
  }

  render() {
    const { username, candidate_id, age, gender, token, created_at, callRequest, result, state, response } = this.state;
    const {profile} = this.props;
    const dateTaken = moment(created_at).format('DD MMMM YYYY');
    const timeTaken = moment(created_at).format('hh:mmA');
    const action = callRequest && callRequest.type;
    return (
      <div className="view-submission">
      {
        response &&
        <React.Fragment>
          <div className="top">
          <h4>View Assessment</h4>
          <div className="candidate-info">
            <div className="info">
              <h5>USERNAME</h5>
              <h4>{username}</h4>
            </div>
            <div className="info">
              <h5>AGE</h5>
              <h4>{age}</h4>
            </div>
            <div className="info">
              <h5>GENDER</h5>
              <h4>{gender}</h4>
            </div>
            <div className="info">
              <h5>RESULT</h5>
              <h4>{state}</h4>
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="image-div">
            <img src={`${result}?access_token=${token}`} alt="" className="result-img"/>
          </div>
          <div className="date-time-row">
            <p className="date-time">{dateTaken} • {timeTaken}</p>
            {!action && <p className='action'>No Action</p>}
            {action && 
              <p className="action">
                {action}
                <img className='request-icon' alt='icon' src={request} />
              </p>
            }
          </div>
          {
            profile !== false &&
            <div className="btn-row">
              <button onClick={()=>this.viewProfile(candidate_id)}>All user’s submissions</button>
            </div>
          }
        </div>
        </React.Fragment>
      }
      {
        !response &&
        <div className="loader-div">
          <img src={loader} alt=""/>
        </div>
      }
      </div>
    )
  }
}

export default withRouter(ViewSubmission);