import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { CSVLink } from "react-csv";
import moment from 'moment';
import DatePicker from "react-datepicker";
import helper from '../helper';
import Header from '../shared/Header';
import Pagination from '../shared/Pagination';
import ViewSubmission from './ViewSubmission';
import Modal from '../modal/Modal';
import submissionsIcon from '../../assets/svg/submissions.svg';
import down from '../../assets/svg/arrow-down.svg';
import updown from '../../assets/svg/updown.svg';
import request from '../../assets/svg/new_call_request.svg';
import loader from '../../assets/svg/loader.svg';

import "react-datepicker/dist/react-datepicker.css";


const SearchBarContainer = styled.div`
  width: auto;
  margin: 0; padding: 0;
  position: relative;
  top: 9px;
`;
const SearchBarWrapper = styled.div`
  position: relative;
  margin: 0; padding: 0;
  display: flex;
  align-items: center;
  border: 1px solid #CACACA;
  padding: 0 12px;
  border-radius: 4px;
  float: right;
`;
const Icon = styled.svg`
  margin-right: 5px;
`;
const Input = styled.input`
  margin: 0; 
  padding: 0;
  height: 34px;
  box-sizing: border-box;
  border-radius: 4px;
  border: none;
  outline: 0;
  color: #7E7E7E;

  &:focus {
    border: none;
    outline: 0;
  }
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  align-content: center;
`;
const TopWrapper = styled.div`
  display: flex;
  align-items: baseline;
  padding: 5px 0;
`;

const TopIcon = styled.img`
  padding: 0;
  margin: 0;
  margin-right: 12px;
  position: relative;
  top: 9px;

`;
const Text = styled.p`
    color: #4E4E4E;
    font-size: 20px;
    margin-right: 10px;
`;
const Export = styled.div`
  background: #F4F4F4;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  padding: 5px 13px;
  height: 30px;
  cursor: pointer;
`;
const ExportText = styled.p`
  margin: 0;
  color: #484848;
`;



class TestSubmissions extends Component {
  csvLink = React.createRef()
  state = {
    submissions: [],
    response: false,
    show: false,
    page: 1,
    perPage: 10,
    totalPages: 0,
    total: 0,
    skip: 0,
    filter: '',
    minAge: 1,
    maxAge: 100,
    location: '',
    gender: '',
    action: '',
    status: '',
    startDate: '',
    endDate: '',
    csvData: [],
  }
  componentDidMount(){
    this.calculatePagination();
    this.loadSubmissions();
    const { match: { params } } = this.props;
    const { candidate_id, test_id } = params;
    if(candidate_id && test_id){
      this.setState({
        show: true,
      })
    }
  }

  fetchSubmissions = async () => {
    const { skip, perPage, location, minAge, maxAge, gender, action, startDate, endDate, status } = this.state;
    const formatStartDate = moment(startDate).format('YYYY-MM-DD');
    const formatEndDate = moment(endDate).format('YYYY-MM-DD');

    let qs = [];
    if (perPage) qs[qs.length] = `limit: ${perPage}`;
    if (skip) qs[qs.length] = `skip: ${skip}`;
    if (location) qs[qs.length] = `location: "${location}"`;
    if (minAge!==1 || maxAge!==100) qs[qs.length] = `age: { min: ${minAge}, max: ${maxAge} }`;
    if (gender) qs[qs.length] = `gender: ${gender.toUpperCase()}`;
    if (action) qs[qs.length] = `action: ${action.toUpperCase()}`;
    if (startDate && endDate) qs[qs.length] = `created_at: { start: "${formatStartDate}", end: "${formatEndDate}" }`;
    if (status) qs[qs.length] = `status: ${status.toUpperCase()}`;

    const query = `{
      submissions(${qs.join(',')}){
        collection{
          username,
          candidate_id,
          location,
          age,
          gender,
          test{
            id,
            created_at,
            result,
            state,
            callRequest{
              type
            }
          }
        }
        meta {
          total
        }
      }
    }`
    const data = await helper.apolloQuery(query);
    // if(data.error){
    //   return <Redirect to='/login'/>
    // }
    const { submissions } = data;
    const collection = submissions && submissions.collection;
    const meta = submissions && submissions.meta;
    const total = meta && meta.total;
    await this.setState({
      total: total || 0,
      submissions: collection || [],
      response: true,
    }, () => {
      this.calculatePagination();
    })
  }
  
  exportCSV = async () => {
    const { total, location, minAge, maxAge, gender, action, startDate, endDate, status } = this.state;
    const formatStartDate = moment(startDate).format('YYYY-MM-DD');
    const formatEndDate = moment(endDate).format('YYYY-MM-DD');

    let qs = [`limit: ${total}`, 'skip: 0'];
    if (location) qs[qs.length] = `location: "${location}"`;
    if (minAge!==1 || maxAge!==100) qs[qs.length] = `age: { min: ${minAge}, max: ${maxAge} }`;
    if (gender) qs[qs.length] = `gender: ${gender.toUpperCase()}`;
    if (action) qs[qs.length] = `action: ${action.toUpperCase()}`;
    if (startDate && endDate) qs[qs.length] = `created_at: { start: "${formatStartDate}", end: "${formatEndDate}" }`;
    if (status) qs[qs.length] = `status: ${status}`;

    const query = `{
      submissions(${qs.join(',')}){
        collection{
          username,
          location,
          age,
          gender,
          test{
            state,
            created_at,
          }
        }
      }
    }`
    const data = await helper.apolloQuery(query);
    if(data.error){
      return <Redirect to='/login'/>
    }
    const { submissions } = data;
    const collection = submissions && submissions.collection;
    let csvData = [];
    collection.forEach(item => {
      const {username, location, age, gender, test} = item;
      const {state, created_at} = test;
      let entry = {
        username,
        age,
        gender: gender==='RATHER_NOT_SAY'?'Rather Not Say':gender,
        location,
        result: state,
        date: created_at,
      }
      csvData = [...csvData, entry]
    })
    await this.setState({
      csvData,
    },()=>{
      this.csvLink.current.link.click()
    })
  }

  calculatePagination = async () => {
    const { perPage, total } = this.state;
    const pagesObject = await helper.calculateTotalPages(total, perPage)
    const { entriesLength, totalPages } = pagesObject;
    await this.setState({
      totalSubmissions: entriesLength,
      totalPages,
    })
  }

  loadSubmissions = () => {
    const { match: { params } } = this.props;
    const { page, per_page } = params;
    const parsedPage = parseInt(page);
    const parserPerPage = parseInt(per_page)
    const skip = (parsedPage - 1) * parserPerPage;
    this.setState({
      skip,
      page: parsedPage || localStorage.getItem('page'),
      perPage: parserPerPage || localStorage.getItem('per_page')
    }, () => {
      this.fetchSubmissions();
    })
  }

  next = () => {
    const { page, perPage, totalPages } = this.state;
    const nextObject = helper.handleNextPage(page, perPage, totalPages)
    const skip = nextObject && nextObject.skip;
    const newPage = nextObject && nextObject.newPage;
    if(nextObject){
      this.setState({
        response: false,
        submissions: []
      })
      this.loadPage(newPage, perPage);
      this.setState({
        skip,
        page: newPage,
      }, () => {
        this.fetchSubmissions();
      })
    }
  }

  prev = () => {
    const { page, perPage } = this.state;
    const previousObject = helper.handlePreviousPage(page, perPage)
    const skip = previousObject && previousObject.skip;
    const newPage = previousObject && previousObject.newPage;
    if(previousObject){
      this.setState({
        response: false,
        submissions: []
      })
      this.loadPage(newPage, perPage);
      this.setState({
        skip,
        page: newPage,
      }, () => {
        this.fetchSubmissions();
      })
    }
  }

  handlePage = (page, perPage) => {
    this.loadPage(page, perPage);
    const skip = (page - 1) * perPage; 
    this.setState({
      skip,
      page,
      submissions: [],
      response: false,
    }, () => {
      this.fetchSubmissions();
    })
  }

  loadPage = (page, perPage) => {
    this.props.history.push({
      pathname: `/submissions/${page}/${perPage}`,
    })
  }

  openModal = (submission, candidate_id, test_id) => {
    const {page, perPage} = this.state;
    localStorage.setItem('page', page)
    localStorage.setItem('per_page', perPage)
    this.props.history.push({
      pathname: `/submissions/view/${candidate_id}/${test_id}`,
      candidate_id,
      test_id,
      submission
    })
    this.setState({
      show: true,
      details: submission,
    })
  }

  closeModal = () => {
    const { page, perPage } = this.state;
    localStorage.removeItem('page');
    localStorage.removeItem('per_page');
    this.props.history.push({
      pathname: `/submissions/${page}/${perPage}`
    })
    this.setState({
      show: false
    })
  }

  handleFilter = (value) => {
    this.setState({
      filter: value,
    })
  }

  closeFilter = () => {
    this.setState({
      filter: '',
    })
  }

  handleAge = (e, type) => {
    const age = e.target.value;
    if(type==='min' && age!==0){
      this.setState({
        minAge: age,
        submissions: [],
        response: false,
      }, () => {
        if(age){
          this.fetchSubmissions();
        }
      })
    }
    if(type==='max' && age!==0){
      this.setState({
        maxAge: age,
        submissions: [],
        response: false,
      }, ()=>{
        if(age){
          this.fetchSubmissions();
        }
      })
    }
  }

  handleGender = (gender) => {
    this.setState({
      gender,
      submissions: [],
      response: false,
    }, ()=>{
      this.fetchSubmissions();
    })
  }

  handleAction = (action) => {
    this.setState({
      action,
      submissions: [],
      response: false,
    }, ()=>{
      this.fetchSubmissions();
    })
  }

  handleStatus = (status) => {
    this.setState({
      status,
      submissions: [],
      response: false,
    }, ()=>{
      this.fetchSubmissions();
    })
  }

  handleStartDate = (date) => {
    this.setState({
      startDate: date,
      submissions: [],
      response: false,
    }, () => {
      const {startDate, endDate} = this.state;
      if(startDate&&endDate){
        this.fetchSubmissions();
      }
    })
  }

  handleEndDate = (date) => {
    this.setState({
      endDate: date,
      submissions: [],
      response: false,
    }, () => {
      const {startDate, endDate} = this.state;
      if(startDate&&endDate){
        this.fetchSubmissions();
      }
    })
  }

  clearFilters = (type) => {
    if (type === 'all'){
      this.setState({
        gender: '',
        minAge: 1,
        maxAge: 100,
        action: '',
        startDate: '',
        endDate: '',
        status: '',
        submissions: [],
        response: false,
      }, () => {
        this.fetchSubmissions();
      })
    }
    if (type === 'gender'){
      this.setState({
        gender: '',
        submissions: [],
        response: false,
      }, () => {
        this.fetchSubmissions();
      })
    }
    if (type === 'age'){
      this.setState({
        minAge: 1,
        maxAge: 100,
        submissions: [],
        response: false,
      }, () => {
        this.fetchSubmissions();
      })
    }
    if (type === 'action'){
      this.setState({
        action: '',
        submissions: [],
        response: false,
      }, () => {
        this.fetchSubmissions();
      })
    }
    if (type === 'status'){
      this.setState({
        status: '',
        submissions: [],
        response: false,
      }, () => {
        this.fetchSubmissions();
      })
    }
    if (type === 'date'){
      this.setState({
        startDate: '',
        endDate: '',
        submissions: [],
        response: false,
      }, () => {
        this.fetchSubmissions();
      })
    }
  }

  render() {
    const { show, submissions, totalSubmissions, details, response, filter, minAge, maxAge, totalPages, page, perPage, gender, action, startDate, endDate, csvData, status } = this.state;
    let headers = [
      { label: "Username", key: "username" },
      { label: "Age", key: "age" },
      { label: "Gender", key: "gender" },
      { label: "Location", key: "location" },
      { label: "Result", key: "result" },
      { label: "Date Created", key: "date" }
    ];
    return (
      <React.Fragment>
        <Header page='submissions'/>
        <div id='testSubmissions'>
          <div className='left'>
            <p className='title'>Filter View</p>
            <div className='filter-label-top'>
              <p>All</p>
              <div className='counter'>
                <p>{totalSubmissions}</p>
              </div>
            </div>
            <div className='filter-label' onClick={filter==='age'?this.closeFilter:() => this.handleFilter('age')}>
              <p>Age</p>
              <img alt='open' src={down}/>
            </div>
            {
              filter === 'age' &&
              <div className='age-filter'>
                <div>
                  min
                  <input type="number" value={minAge} onChange={(e) => this.handleAge(e, 'min')}/>
                </div>
                -
                <div>
                  max
                  <input type="number" value={maxAge} onChange={(e) => this.handleAge(e, 'max')}/>
                </div>
              </div>
            }
            <div className='filter-label' onClick={filter==='gender'?this.closeFilter:() => this.handleFilter('gender')}>
              <p>Gender</p>
              <img alt='open' src={down} />
            </div>
            {
              filter === 'gender' &&
              <div className="filter-options">
                <p onClick={()=>this.handleGender('male')}>Male</p>
                <p onClick={()=>this.handleGender('female')}>Female</p>
                <p onClick={()=>this.handleGender('RATHER_NOT_SAY')}>Rather Not Say</p>
              </div>
            }
            <div className='filter-label' onClick={filter==='action'?this.closeFilter:() => this.handleFilter('action')}>
              <p>User Action</p>
              <img alt='open' src={down} />
            </div>
            {
              filter === 'action' &&
              <div className="filter-options">
                <p onClick={()=>this.handleAction('requested')}>Call Requested</p>
                <p onClick={()=>this.handleAction('initiated')}>Call Initiated</p>
                <p onClick={()=>this.handleAction('none')}>No action</p>
              </div>
            }
            <div className='filter-label' onClick={filter==='status'?this.closeFilter:() => this.handleFilter('status')}>
              <p>Test Result</p>
              <img alt='open' src={down} />
            </div>
            {
              filter === 'status' &&
              <div className="filter-options">
                <p onClick={()=>this.handleStatus('positive')}>Positive</p>
                <p onClick={()=>this.handleStatus('negative')}>Negative</p>
                <p onClick={()=>this.handleStatus('invalid')}>Invalid</p>
              </div>
            }
            <div className='filter-label' onClick={filter==='date'?this.closeFilter:() => this.handleFilter('date')}>
              <p>Date Submitted</p>
              <img alt='open' src={down} />
            </div>
            {
              filter === 'date' &&
              <div className="filter-options">
                <p className="date-label">Start Date</p>
                <DatePicker
                  selected={startDate}
                  onChange={this.handleStartDate}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/YYYY"
                  className="date-input"
                />
                <p className="date-label">End Date</p>
                <DatePicker
                  selected={endDate}
                  onChange={this.handleEndDate}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/YYYY"
                  className="date-input"
                />
              </div>
            }
            <div className="filters">
              {
                (minAge!==1||maxAge!==100) &&
                <div className="clear-filter" onClick={()=>{this.clearFilters('age')}}>x {minAge}-{maxAge}</div>
              }
              {
                gender &&
                <div className="clear-filter" onClick={()=>{this.clearFilters('gender')}}>x {gender==='RATHER_NOT_SAY'?'Rather Not Say':gender}</div>
              }
              {
                status &&
                <div className="clear-filter" onClick={()=>{this.clearFilters('status')}}>x {status}</div>
              }
              {
                status &&
                <div className="clear-filter" onClick={()=>{this.clearFilters('status')}}>x {status}</div>
              }
              {
                action &&
                <div className="clear-filter" onClick={()=>{this.clearFilters('action')}}>
                  x
                  {action==='none'&&' no action'}
                  {action==='requested'&&' call requested'}
                  {action==='initiated'&&' call initiated'}
                </div>
              }
              {
                (startDate&&endDate) &&
                <div className="clear-filter" onClick={()=>{this.clearFilters('date')}}>x {moment(startDate).format('DD/MM/YYYY')}-{moment(endDate).format('DD/MM/YYYY')}</div>
              }
              {
                (gender||minAge!==1||maxAge!==100||action||(startDate&&endDate)) &&
                <div className="clear-filter" onClick={()=>{this.clearFilters('all')}}>x clear all</div>
              }
            </div>
          </div>
          <div className='right'>
            <div className='content'>
              <TopContainer>
                <TopWrapper>
                  <TopIcon src={submissionsIcon} className='icon' />
                  <Text>Test Submissions</Text>
                  <Export onClick={this.exportCSV}>
                    <ExportText>Export CSV</ExportText>
                  </Export>

                  <CSVLink data={csvData}
                    headers={headers}
                    filename={`itest-submissions-${Date.now()}.csv`}
                    ref={this.csvLink}
                    className="hidden-csv">
                  </CSVLink>

                </TopWrapper>

                <SearchBarContainer>
                  <SearchBarWrapper>
                  <Icon width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.6448 10.229C11.5058 8.96905 11.8844 7.41449 11.7052 5.87443C11.526 4.33437 10.8022 2.92161 9.67773 1.91706C8.55322 0.9125 7.11037 0.38972 5.63605 0.452659C4.16172 0.515599 2.76392 1.15965 1.72056 2.25675C0.677196 3.35386 0.0646958 4.82366 0.00483942 6.37392C-0.055017 7.92418 0.442155 9.44136 1.3975 10.6238C2.35285 11.8062 3.6964 12.5673 5.16102 12.7557C6.62564 12.9441 8.10405 12.546 9.3023 11.6406L12.6569 15.1714L14 13.7591L10.6448 10.229ZM5.89391 10.8047C5.1115 10.8047 4.34667 10.5608 3.69613 10.1037C3.04559 9.64662 2.53855 8.99697 2.23914 8.23689C1.93973 7.47681 1.86139 6.64045 2.01403 5.83355C2.16666 5.02666 2.54343 4.28548 3.09667 3.70374C3.64991 3.122 4.35478 2.72584 5.12215 2.56534C5.88952 2.40483 6.68491 2.48721 7.40776 2.80204C8.1306 3.11688 8.74843 3.65003 9.18311 4.33408C9.61779 5.01813 9.8498 5.82236 9.8498 6.64506C9.84862 7.74789 9.43147 8.8052 8.68985 9.58502C7.94823 10.3648 6.94271 10.8035 5.89391 10.8047Z" fill="#148393"/>
                  </Icon>

                    <Input placeholder='Search for a name' />
                  </SearchBarWrapper>
                </SearchBarContainer>

              </TopContainer>
              <div className='table-container'>
                <table>
                  <tbody>
                    <tr>
                      <th>USERNAME <img className='updown' alt='open' src={updown} /></th>
                      <th>LOCATION <img className='updown' alt='open' src={updown} /></th>
                      <th>AGE <img className='updown' alt='open' src={updown} /></th>
                      <th>DATE TAKEN <img className='updown' alt='open' src={updown} /></th>
                      <th>STATUS <img className='updown' alt='open' src={updown} /></th>
                      <th>ACTION <img className='updown' alt='open' src={updown} /></th>
                      <th></th>
                    </tr>
                    {
                      submissions.map(submission => {
                        const { candidate_id, username, location, age, test } = submission;
                        const { id, created_at, callRequest, state } = test;
                        const dateTaken = moment(created_at).format('DD MMMM YYYY');
                        const action = callRequest && callRequest.type;
                        return(
                          <tr key={id}>
                            <td>{username}</td>
                            <td>{location}</td>
                            <td>{age}</td>
                            <td>{dateTaken}</td>
                            <td className={state==="positive"?"capitalize red":"capitalize"}>{state}</td>
                            <td>
                              {!action && 'No Action'}
                              {action && 
                                <React.Fragment>
                                  {action}
                                  <img className='request-icon' alt='icon' src={request} />
                                </React.Fragment>
                              }
                            </td>
                            <td>
                              <span className='view' onClick={() => this.openModal(submission, candidate_id, id)}>View Submission</span>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
                {
                  response && submissions.length===0 &&
                  <div className="loader-div">
                    <p>There are no submissions on the system yet</p>
                  </div>
                }
                {
                  !response &&
                  <div className="loader-div">
                    <img src={loader} alt=""/>
                  </div>
                }
              </div>
            </div>
            {
              response && submissions.length>0 &&
              <div className='bottom'>
                <Pagination
                  totalPages={totalPages}
                  page={page}
                  handlePage={this.handlePage}
                  perPage={perPage}
                  prev={this.prev}
                  next={this.next}
                />
              </div>
            }
          </div>
        </div>
        <Modal show={show} handleClose={this.closeModal}>
          {show && <ViewSubmission submission={details} />}
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(TestSubmissions);