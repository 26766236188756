
import { createApolloFetch } from 'apollo-fetch';
import { baseUrl } from './url';
// import { Redirect } from 'react-router-dom';

const uri = `${baseUrl}/query`;
const apolloFetch = createApolloFetch({ uri });

export default class helper {
  static range(start, end, interval) {
    let gen = [];
    
    if (typeof start !== 'number' || typeof end !== 'number') {
      return gen;
    }

    interval = typeof interval === 'number' ? interval : 1;

    for (let i = start; i <= end; i+=interval) {
      gen.push(i);
    }

    return gen;
  }

  static async apolloQuery(query) {
    // const token = await `Bearer ${await this.verifyToken()}`;
    const token = `Bearer ${localStorage.getItem('access_token')}`;

    apolloFetch.use(({ request, options }, next) => {
      if (!options.headers) {
        options.headers = {};
      }
      options.headers['authorization'] = token;
    
      next();
    });

    apolloFetch.useAfter(({ response }, next) => {
      if (response.status === 401) {
        localStorage.removeItem('access_token');
        const error = {
          status: response.status,
          error: true,
        }
        return error;
      }
      next();
    });
  
    const response = await apolloFetch({ query })
    const { data } = response;
    console.log(data);
    return data;
  }

  static calculateTotalPages (totalEntries, itemsPerPage) {
    const entriesLength = totalEntries;
    const totalPages = entriesLength/itemsPerPage;
    return {
      entriesLength,
      totalPages: Math.ceil(totalPages),
    }
  }

  static handleNextPage (page, perPage, totalPages) {
    if(page < totalPages){
      const newPage = page + 1;
      const skip = (newPage - 1) * perPage;
      return {
        skip,
        newPage
      }
    }
  }

  static handlePreviousPage (page, perPage) {
    if(page > 1){
      const newPage = page - 1;
      const skip = (newPage - 1) * perPage;
      return {
        skip,
        newPage
      }
    }
  }
}


