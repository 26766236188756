import React, { Component } from 'react'
import '../../App.css';
import { Link } from 'react-router-dom';
import overview from '../../assets/svg/nav_overview.svg';
import submissions from '../../assets/svg/nav_submissions.svg';
import users from '../../assets/svg/nav_users.svg';
import assessments from '../../assets/svg/nav_assessments.svg';
import locations from '../../assets/svg/nav_locations.svg';
import admin from '../../assets/svg/nav_admin.svg';
import logo from '../../assets/svg/4yby_logo.svg'

export default class Header extends Component {

  render() {
    const { page } = this.props;
    return (
      <div id='header'>
        <img className='logo' alt='4yby' src={logo} />
        <nav className='nav'>
          <Link to="/" className={page === 'overview'?`active`:''}>
            <img alt='Overview' src={overview} /> Overview
          </Link>
          <Link to="/submissions/1/10" className={page === 'submissions'?`active`:''}>
            <img alt='Submissions' src={submissions} /> Submissions
          </Link>
          <Link to="/users/1/10" className={page === 'users'?`active`:''}>
            <img alt='Users' src={users} /> Users
          </Link>
          <Link to="/assessments/1/10" className={page === 'assessments'?`active`:''}>
            <img alt='Users' src={assessments} /> Assessments
          </Link>
          <Link to="/centers" className={page === 'centers'?`active`:''}>
            <img alt='Locations' src={locations} /> YF Centers
          </Link>
        </nav>
        <Link to="/admin-profile" className='admin'>
          <img alt='Profile' src={admin} />
          <p>Admin</p>
          {/* <img alt='Open' src={down} /> */}
        </Link>
      </div>
    );
  }
} 
