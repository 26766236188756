import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import signinErrorIcon from '../../assets/svg/signin_error.svg';
import loader from '../../assets/svg/loader.svg';
import { baseUrl } from '../url';
import helper from '../helper';

class SignIn extends Component {
  state = {
    error: false,
    username: '',
    password: '',
    request: false,
  }

  handleUsername = (e) => {
    this.setState({
      username: e.target.value,
      emailError: false,
      signinError: '',
    })
  }

  handlePassword = (e) => {
    this.setState({
      password: e.target.value,
      signinError: '',
    })
  }

  validateEmail = (e) => {
    const { username } = this.state;
    const correctEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(username);
    this.setState({
      emailError: !correctEmail
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { username, password, emailError } = this.state;
    const signinError = !username||!password?'Please enter your email and password':'';
    this.setState({
      signinError,
    })
    const noError = username&&password&&!emailError;
    if(noError){
      this.setState({
        request: true
      })
      fetch(`${baseUrl}/admin/auth/login`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        credentials: 'same-origin',
        body: JSON.stringify({
          email: username,
          password,
        })
      }).then(response => {
        this.setState({
          request: false,
        })
        if (helper.range(200, 299).indexOf(response.status) !== -1) {
          return response.json();
        }
      }).then(responseJson => {
        if (responseJson) {
          const { token, _id } = responseJson;
          localStorage.setItem('access_token', token);
          localStorage.setItem('admin_id', _id);
          this.navigateToHome();
        }
      })
    }
  }

  navigateToHome = () => {
    this.props.history.push({
      pathname: '/overview',
    })
  }

  forgotPassword = () => {
    this.props.history.push({
      pathname: '/password/reset',
    })
  }


  render() {
    const { emailError, signinError, request } = this.state;
    const { from } = this.props.location.state || { from: { pathname: '/' } }
    const token = localStorage.getItem('access_token'); 
    if ( token ) {
      return <Redirect to={from}/>;
    }
    return (
      <div id='sign-in'>
        <div className='auth-box'>
          <p className='headerText'>Admin Login</p>
          <div className='form-box'>
            <label htmlFor="email">Email Address</label>
            <input type="text" className={!emailError ? 'email' : 'email-error'} name="email" onChange={this.handleUsername} onBlur={this.validateEmail}/>
            {emailError && <p className='error-text'>Enter a valid email address</p>}
            <label htmlFor="psw">Password</label>
            <input type="password" name="password" onChange={this.handlePassword}/>

            <button type="submit" className="btn" onClick={this.handleSubmit}>Login</button>
          </div>
          <div className='forgot-div'>
            <p className='forgot' onClick={this.forgotPassword}>Forgot Password?</p>
          </div>
          {
            signinError &&
            <div className='error-div'>
              <img src={signinErrorIcon} alt="" />
              <p className='error'>{signinError}</p>
            </div>
          }
          {
            request && 
            <div className="loader-div">
              <img src={loader} alt=""/>
            </div>
          }
        </div>
      </div>
    );
  }
} 

export default withRouter(SignIn);
