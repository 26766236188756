import React from 'react';
import down from '../../assets/svg/arrow-down.svg';

export default function Pagination(props) {
  const {page, totalPages, handlePage, perPage, prev, next} = props;
  let pages = [];
    let currentPage = page, 
        range = 5,
        start = 1;
    if(totalPages<range){
      range = totalPages - 1;
    }
    if (currentPage < (range / 2) + 1 ) {
        start = 1;
    } else if (currentPage >= (totalPages - (range / 2) )) {
        start = Math.floor(totalPages - range + 1);

    } else {
        start = (currentPage - Math.floor(range / 2));
    }
    let firstPage;
    firstPage = <button className={page===1?'active pagination-btns':'pagination-btns'} key={1} onClick={() => handlePage(1, perPage)}>{1}</button>
    pages = [...pages, firstPage];

    if(page-1>3){
      let dots;
      dots = <button className='dots' key="dots">...</button>
      pages = [...pages, dots]
    }

    for (let i = start; i <= ((start + range) - 1); i++) {
        if(i!==totalPages&&i!==1){
          let pageNumber;
          pageNumber = <button className={page===i?'active pagination-btns':'pagination-btns'} key={i} onClick={() => handlePage(i, perPage)}>{i}</button>
          pages = [...pages, pageNumber]
        }
    }
    if(totalPages-page>3){
      let dots;
      dots = <button className='dots' key="dots">...</button>
      pages = [...pages, dots]
    }
    if(totalPages!==1){
      let lastPage;
      lastPage = <button className={page===totalPages?'active pagination-btns':'pagination-btns'} key={totalPages} onClick={() => handlePage(totalPages, perPage)}>{totalPages}</button>
      pages = [...pages, lastPage]
    }
    // console.log(totalPages);
  return (
    <React.Fragment>
      <button className='entries-per-page'>Entries per page: <span className='count'>10</span> <img className='entries' alt='open' src={down} /></button>
      <button className='pagination-btns' onClick={prev}>Previous</button>
      {pages}
      <button className='pagination-btns' onClick={next}>Next</button>
    </React.Fragment>
  )
}
